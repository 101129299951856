import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import ContactHeroImage from '../app/gallery/contact_me_hero.jpg'

const useStyles = makeStyles({
    card: {width: '500px', paddng: '50px'},
});

export default function ContactScreen() {
    const classes = useStyles();

    return (

        <div className={'hero-contact'}>
            <div style={{
                height: '100%',
                // minHeight: '500px',
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'stretch',
                flexWrap: 'wrap'
            }}>
                <div style={{
                    backgroundColor: 'rgba(0,0,0,.05)',
                    padding: '84px 5vw 84px',
                    flex: 3,
                    minWidth: '301px'
                    // flexBasis: '100%'
                }}>
                    <br/>
                    <br/>
                    <Typography className={'contact-msg'} variant="h6">
                        Please contact me for bookings and custom prints via email.
                    </Typography>
                    <br/>
                    <br/>
                    <br/>
                    <Typography className={'contact-email'} variant="body2" style={{wordBreak: 'break-all'}}>
                        vectorsq.photography@gmail.com
                    </Typography>

                </div>

                <div style={{
                    minWidth: '300px',
                    minHeight: '200px',
                    backgroundImage: `url(${ContactHeroImage})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    flex: 5
                }}>

                </div>
            </div>
        </div>
    );

}
