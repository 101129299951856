import React, {useRef, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import PhotoSliderNavButtons from "./PhotoSliderNavButtons";


// @ts-ignore
export default function PhotoSliderSlick(props) {

    const [slideIndex, setSlideIndex] = useState(0);
    const carouselRef = useRef(null);
    const settings = {
        className: "slider variable-width",

        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        variableWidth: true,

        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,

        loop: true,
        autoplay: true,
        autoplaySpeed: 1500,
        beforeChange: (current: number, next: number) => setSlideIndex(next),
    };

    const photos = (props.photos || []);

    function nextSlide() {
        // @ts-ignore
        carouselRef.current.slickGoTo(slideIndex + 1);
    }

    function prevSlide() {
        // @ts-ignore
        carouselRef.current.slickGoTo(slideIndex - 1);
    }

    // @ts-ignore
    return (
        <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
            <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
                <Slider {...settings} ref={carouselRef}>

                    {
                        photos.map((photo: any, i: number) =>
                            <div key={i}>
                                <img src={photo.src} style={{
                                    opacity: '1',
                                    transition: 'opacity .01s linear!important',
                                    width: 'auto',
                                    display: 'block',
                                    verticalAlign: 'middle',
                                    padding: '0px 20px'
                                }}/>
                            </div>
                        )
                    }
                </Slider>
            </div>
            <div style={{paddingTop: '60px'}}>
                <PhotoSliderNavButtons
                    prevSlide={prevSlide}
                    nextSlide={nextSlide}
                    slideIndex={slideIndex}
                    slideMax={photos.length}
                />
            </div>
        </div>
    );

}