import React from "react";

import {Button, Typography} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

interface Props {
    prevSlide: () => void,
    nextSlide: () => void,
    slideIndex: number,
    slideMax: number
}

export default function PhotoSliderNavButtons(props: Props) {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button color="inherit" className={"spaced-text-button"} onClick={props.prevSlide}>
                <ArrowBackIcon/>
            </Button>

            <div style={{width: '150px', textAlign: 'center', paddingTop: '.3rem'}}>
                <Typography>{props.slideIndex + 1} / {props.slideMax}</Typography>
            </div>

            <Button color="inherit" className={"spaced-text-button"} onClick={props.nextSlide}>
                <ArrowForwardIcon/>
            </Button>
        </div>
    );
}