import React from 'react';
import {makeStyles} from "@material-ui/core";
import Hero from "./Hero";
import PhotoSliderSlick from "./PhotoSliderSlick";

const useStyles = makeStyles({
    root: {},
});

interface Props {
    title: string,
    heroPhoto: string,
    photos: Array<any>
}

export default function PortfolioGallery(props: Props) {
    const classes = useStyles();

    return (
        <div>
            <Hero image={props.heroPhoto} title={props.title}/>

            <div className={'portfolio-gallery'} id={'view-gallery'}>
                <PhotoSliderSlick photos={props.photos}/>
            </div>

            <div style={{height: '10vh'}}/>
        </div>
    );
}
