import P1 from '../gallery/portrait/P1.jpg'
import P2 from '../gallery/portrait/P2.jpg'
import P3 from '../gallery/portrait/P3.jpg'
import P4 from '../gallery/portrait/P4.jpg'
import P5 from '../gallery/portrait/P5.jpg'
import P7 from '../gallery/portrait/P7.jpg'
import P8 from '../gallery/portrait/P8.jpg'
import P10 from '../gallery/portrait/P10.jpg'
import P11 from '../gallery/portrait/P11.jpg'
import P12 from '../gallery/portrait/P12.jpg'

export const PortraitPhotos = [
    {
        src: P1,
    },
    {
        src: P2,
    },
    {
        src: P3,
    },
    {
        src: P5
    },
    {
        src: P7
    },
    {
        src: P8
    },
    {
        src: P11
    },
    {
        src: P4
    },
    {
        src: P10
    },
    {
        src: P12
    },
];
