import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import './style/App.css';
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"

import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import LandingScreen from './LandingScreen';
import {Nav, NavItems} from "./components/Nav";
import PortfolioGallery from "./components/PortfolioGallery";


import PortraitHeroImage from '../app/gallery/portrait_hero.jpg'
import FineArtHeroImage from '../app/gallery/fineart_hero.jpg'
import {PortraitPhotos} from "./gallery/PortraitPhotos";
import {FineArtPhotos} from "./gallery/FineArtsPhotos";
import ContactScreen from './ContactScreen';

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Montserrat',
        ].join(','),
    },
});


function App() {

    return (

        <ThemeProvider theme={theme}>
            <Router>
                <div>
                    <Nav/>

                    <Switch>
                        <Route exact path="/">
                            <LandingScreen/>
                        </Route>
                        <Route exact path={NavItems.contact.link}>
                            <ContactScreen/>
                        </Route>
                        <Route exact path={NavItems.portfolio.portrait.link}>
                            <PortfolioGallery title={"PORTRAIT GALLERY"} heroPhoto={PortraitHeroImage} photos={PortraitPhotos}/>
                        </Route>
                        <Route exact path={NavItems.portfolio.fineArt.link}>
                            <PortfolioGallery title={"FINE ART GALLERY"} heroPhoto={FineArtHeroImage} photos={FineArtPhotos}/>
                        </Route>
                    </Switch>

                </div>

            </Router>

        </ThemeProvider>
    );
}

export default App;
