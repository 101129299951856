import React, {useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import Hero from './components/Hero';

import PortraitHeroImage from '../app/gallery/portrait_hero.jpg'
import Slider from "react-slick";
import PhotoSliderNavButtons from "./components/PhotoSliderNavButtons";
import FineArtHeroImage from "./gallery/fineart_hero.jpg";
import {useHistory} from "react-router-dom";
import {NavItems} from "./components/Nav";

const useStyles = makeStyles({
    root: {},
});

export default function LandingScreen() {
    const history = useHistory();
    const classes = useStyles();

    const [slideIndex, setSlideIndex] = useState(0);
    const carouselRef = useRef(null);
    const settings = {
        className: "slider variable-width",
        infinite: true,
        swipeToSlide: true,
        autoplay: true,
        fade: true,
        loop: true,
        autoplaySpeed: 5000,
        beforeChange: (current: number, next: number) => setSlideIndex(next),
    };

    function nextSlide() {
        // @ts-ignore
        carouselRef.current.slickGoTo(slideIndex + 1);
    }

    function prevSlide() {
        // @ts-ignore
        carouselRef.current.slickGoTo(slideIndex - 1);
    }

    // @ts-ignore
    return (
        <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
            <div style={{width: '100%', height: '100%', overflow: 'hidden'}} className={'landing-slider'}>
                <Slider {...settings} ref={carouselRef}>
                    <Hero
                        key={1}
                        image={PortraitHeroImage}
                        title={'PORTRAIT PHOTOGRAPHY'}
                        link={NavItems.portfolio.portrait.link + NavItems.portfolio.portrait.hashLink}
                    />
                    <Hero
                        key={2}
                        image={FineArtHeroImage}
                        title={'FINE ART PHOTOGRAPHY'}
                        link={NavItems.portfolio.fineArt.link + NavItems.portfolio.fineArt.hashLink}
                    />
                </Slider>
            </div>
            <div style={{marginTop: '-60px'}}>
                <PhotoSliderNavButtons
                    prevSlide={prevSlide}
                    nextSlide={nextSlide}
                    slideIndex={slideIndex}
                    slideMax={2}
                />
            </div>
        </div>
    );

}
