import FA1 from '../gallery/fineArt/FA1.jpg'
import FA2 from '../gallery/fineArt/FA2.jpg'
import FA4 from '../gallery/fineArt/FA4.jpg'
import FA5 from '../gallery/fineArt/FA5.jpg'
import FA6 from '../gallery/fineArt/FA6.jpg'
import FA7 from '../gallery/fineArt/FA7.jpg'
import FA8 from '../gallery/fineArt/FA8.jpg'
import FA9 from '../gallery/fineArt/FA9.jpg'
import FA10 from '../gallery/fineArt/FA10.jpg'

export const FineArtPhotos = [
    {
        src: FA1,
    },
    {
        src: FA2,
    },
    {
        src: FA4
    },
    {
        src: FA5
    },
    {
        src: FA6
    },
    {
        src: FA7
    },
    {
        src: FA8
    },
    {
        src: FA9
    },
    {
        src: FA10
    },
];
