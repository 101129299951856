import {Typography} from "@material-ui/core";
import React from "react";
import {useHistory} from "react-router-dom";
import {HashLink} from 'react-router-hash-link';

interface Props {
    title: string
    image: string
    link?: string
}

// @ts-ignore
export default function Hero(props: Props) {
    const history = useHistory();

    // @ts-ignore
    const handleClick = (e) => {
        // @ts-ignore
        history.push(props.link);
        e.preventDefault();
    };

    return (
        <div className={'hero'} style={{}}>
            <div style={{
                height: '100%',
                width: '100%',
                backgroundImage: `url(${props.image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                position: 'relative'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.15)'
                }}>
                    <Typography variant="h3"
                                style={{color: 'white', textAlign: 'center', fontSize: '2rem', fontWeight: 300}}>
                        {props.title}
                    </Typography>

                    {props.link && (
                        <div style={{position: "absolute", bottom: '20px', width: '100%', cursor: 'pointer'}}>

                            <HashLink key={props.link} to={props.link}>
                                <Typography
                                    align={'center'}
                                    variant="subtitle2"
                                    // onClick={handleClick}
                                    style={{
                                        color: 'white',
                                        fontWeight: 300,
                                        fontSize: '.75rem',
                                        textDecoration: 'underline',
                                        textUnderlineOffset: '3px'
                                    }}>
                                    VIEW GALLERY
                                </Typography>
                            </HashLink>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
