import {
    AppBar,
    Button,
    createStyles,
    Dialog,
    Divider,
    List,
    ListItem,
    makeStyles,
    Theme,
    Toolbar,
    Typography
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import React from "react";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {History} from "history";
import {HashLink} from "react-router-hash-link";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        mobileMenuItem: {
            padding: '20px'
        }
    }),
);

function createButtonProps(props: any, history: History<unknown>) {

    const btnProps = {};

    if (props.link) {
        // @ts-ignore
        btnProps.onClick = () => {
            if (props.onDone) {
                props.onDone();
            }
        }

        // @ts-ignore
        btnProps.component = HashLink;
        // @ts-ignore
        btnProps.to = props.link + props.hashLink;

    }

    return {...props, ...btnProps};
}

function NavLogo() {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Typography variant="h6" align={'center'} className={classes.title} onClick={() => history.push("/")}>
            VECTORSQ
        </Typography>
    );
}

function NavButton(props: any) {
    const btnProps = createButtonProps(props, useHistory());
    return (
        <Button
            color="inherit"
            className={"spaced-text-button"}
            {...btnProps}
        >
            {props.title}
        </Button>
    );
}

function NavListItem(props: any) {
    const classes = useStyles();
    const btnProps = createButtonProps(props, useHistory());

    return (
        <ListItem
            className={classes.mobileMenuItem}
            alignItems='center'
            {...btnProps}
        >
            <Typography style={{width: '100%'}} align="center" variant="h6">
                {props.title}
            </Typography>
        </ListItem>
    );
}

export const NavItems = {
    portfolio: {
        fineArt: {
            title: 'Fine Art Photography',
            link: "/portfolio/fineart",
            hashLink: "#view-gallery"
        },
        portrait: {
            title: 'Portrait Photography',
            link: "/portfolio/portrait",
            hashLink: "#view-gallery"
        }
    },
    contact: {
        title: 'Contact',
        link: "/contact",
        hashLink: ""
    },
    store: {
        title: 'Print Store',
        onClick: () => window.location.href = "https://vectorsq.pixieset.com/store/"
    }
}

export function Nav() {
    const classes = useStyles();
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [portfolioOnly, setPortfolioOnly] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <AppBar position="static" color={"transparent"} elevation={0} style={{height: '84px', padding: '11px'}}>

                <Toolbar className={'show-toolbar-lg'} style={{justifyContent: 'space-between'}}>
                    <div style={{flexGrow: 1, flexBasis: 0}}>
                        <NavButton {...NavItems.portfolio.portrait}/>
                        <NavButton {...NavItems.portfolio.fineArt}/>
                    </div>
                    <div style={{flexGrow: 1, flexBasis: 0}}>
                        <NavLogo/>
                    </div>
                    <div style={{flexGrow: 1, flexBasis: 0, display: 'flex', flexDirection: 'row-reverse'}}>
                        <NavButton {...NavItems.contact}/>
                        <NavButton {...NavItems.store}/>
                    </div>
                </Toolbar>

                <Toolbar className={'show-toolbar-md'} style={{justifyContent: 'space-between'}}>
                    <div style={{flexGrow: 1, flexBasis: 0}}>
                        <NavButton
                            title={'Portfolio'}
                            endIcon={<ArrowDropDownIcon/>}
                            onClick={() => {
                                setPortfolioOnly(true);
                                handleClickOpen();
                            }}
                        />
                    </div>
                    <div style={{flexGrow: 1, flexBasis: 0}}>
                        <NavLogo/>
                    </div>
                    <div style={{flexGrow: 1, flexBasis: 0, display: 'flex', flexDirection: 'row-reverse'}}>
                        <NavButton {...NavItems.contact}/>
                        <NavButton {...NavItems.store}/>
                    </div>
                </Toolbar>

                <Toolbar className={'show-toolbar-sm'} style={{justifyContent: 'space-between'}}>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            setPortfolioOnly(false);
                            handleClickOpen();
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <div style={{flexGrow: 1, flexBasis: 0, marginLeft: '-35px'}}>
                        <NavLogo/>
                    </div>
                </Toolbar>

            </AppBar>

            <Dialog fullScreen open={open} onClose={handleClose}>
                <AppBar position="static" color={"transparent"} elevation={0}>
                    <Toolbar>
                        <div style={{flexGrow: 1, flexBasis: 0}}>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                        </div>
                        <div style={{flexGrow: 1, flexBasis: 0}}>
                        </div>
                    </Toolbar>
                </AppBar>
                <List>
                    <NavListItem {...NavItems.portfolio.portrait} onDone={handleClose}/>
                    <Divider/>
                    <NavListItem {...NavItems.portfolio.fineArt} onDone={handleClose}/>
                    {!portfolioOnly && (
                        <React.Fragment>
                            <Divider/>
                            <NavListItem {...NavItems.store} onDone={handleClose}/>
                            <Divider/>
                            <NavListItem {...NavItems.contact} onDone={handleClose}/>
                        </React.Fragment>
                    )}
                </List>
            </Dialog>
        </div>
    );
}